import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Input, Form, message } from 'antd';
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import styled, { ThemeContext } from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { validateAddressString } from '@glif/filecoin-address';
import { AppDispatch } from '../../state'
import { bindEtherAddressToFilAddress } from 'views/hooks'
import { cidStartAndEnd } from '../Cid'
import { walletconnect } from '../../constants'
import { clearLocalStorage, getSign } from '../../utils/txSign'
import { useFetchFilAddress, useFilAddress, useFilAddressStatus } from 'state/account/hooks'
import { ReactComponent as Close } from '../../assets/img/x.svg'
import { ReactComponent as CopyIcon } from '../../assets/img/copy.svg'
import { ReactComponent as DashboardIcon } from '../../assets/img/dashboard.svg'
import { ReactComponent as LinkIcon } from '../../assets/img/link.svg'
import ETHIcon from '../../assets/img/ethereum-logo.png'
import FilIcon from '../../assets/img/fil.png'
const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  background: #000;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
`

const AccountSection = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0rem 1rem 1.5rem 1rem;`};
  .ant-input{
    border-radius: 4px;
  }
  .ant-input-group{
    display: flex;
    justify-content: space-around;
    .ant-input-group-addon{
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      line-height: 28px;
      border-right: 0;
      padding-left: 14px;
      width: 35px;
      img{
        width: 16px;
      }
    }
    .ant-input{
      border-left: 0;
      border-right: 0;
    }
    &.ant-input-group-compact > *:first-child{
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: none;
      background: #F6F6F6;
    }
    &.ant-input-group-compact > *:last-child{
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: none;
      background: #F6F6F6;
    }
    .ant-input,.ant-btn{
      &:focus, &:hover{
        box-shadow: none;
        border-color: #d9d9d9;
      }
    }
    .ant-input[disabled]{
      background: #F6F6F6;
      color: #000;
    }
    .ant-btn-dangerous{
      border-color: #d9d9d9;
    }
  }
  .ant-btn{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .ant-form-item-label > label{
    display: block;
  }
  .ant-form{
    &>div{
      padding: 0 1rem;
    }
  }
  .contract{
    border-top: 1px solid #DEDEDE;
    div{
      background: #FFF6D8;
      margin: 1rem 0;
      padding: 5px 10px!important;
      font-size: 16px;
    }
  }
  .links{
    display: flex;
    justify-content: space-around;
    position: relative;
    border-top: 1px solid #DEDEDE;
    padding 1rem!important;
    a{
      color: #000;
      display: flex;
      align-items: center;
    }
    &>div{
      display: flex;
      align-items: center;
    }
    &:before{
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      content: '';
      width: 1px;
      background: #DEDEDE;
    }
  }
`

const YourAccount = styled.div`
  padding: 20px 0 0;
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
  }
`

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const AddressLink = styled.a`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.text3};
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  stroke: #fff;
`

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
`

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`

const WalletAction = styled.button`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
const NoticeContent = styled.div`
  background: #F6F6F6;
  border-radius: 4px;
  padding: 10px 20px!important;
  margin: 1rem;
  h4{
    font-weight: bold;
  }
  ol{
    padding-left: 20px;
    a{
      color: #000;
      font-weight: bold;
    }
  }
`
const ConfrimContent = styled.div`
  p{
    text-align: center;
    margin: 10px 0;
  }
  .address{
    background: #F6F6F6;
    border-radius: 4px;
    padding: 15px 40px;
    text-align: center;
    font-size: 20px;
    word-break: break-all;
  }
  .btns{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 30px 0 40px;
  }
`


interface AccountDetailsProps {
  toggleWalletModal: () => void
  openOptions: () => void
}

export default function AccountDetails({
  toggleWalletModal,
  openOptions
}: AccountDetailsProps) {
  const { chainId, account, deactivate, library, connector } = useWeb3React()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch<AppDispatch>()
  const [isLinkFilecoinAddr, setIsLinkFilecoinAddr] = useState<boolean>(false);
  const [showLinkAddr, setShowLinkAddr] = useState<boolean>(false);
  const [cofrimLinkAddr, setCofrimLinkAddr] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(1);
  const [filAddressValidate, setFilAddressValidate] = useState<any>({})
  const history = useHistory();
  const [form] = Form.useForm();
  const fetchFilAddress = useFetchFilAddress();
  const filAddress = useFilAddress();
  const fileAddresStatus = useFilAddressStatus()

  const onFinish = async(values: any) => {
    console.log('Success:', form.getFieldValue('filAddress'));
    if(filAddressValidate.validateStatus === 'error' || !form.getFieldValue('filAddress')){
      return;
    }
    const res = await bindEtherAddressToFilAddress(form.getFieldValue('filAddress'), account, library);
    if(res && res.code !== '200'){
      message.error(res.msg)
    }else {
      setRefresh(refresh + 1)
      message.success('You have successfully bound the Filecoin address')
      fetchFilAddress();
      toggleWalletModal();
    }
  };

  const confirm = () => {
    if(filAddressValidate.validateStatus === 'error' || !form.getFieldValue('filAddress')){
      return;
    }else {
      setCofrimLinkAddr(true); 
    }
  }

  const handleFileAddressChange = async(e: any) => {
    const res = await validateAddressString(e.target.value);
    setFilAddressValidate(validateFilAddress(res))
  }

  const validateFilAddress = (result: boolean) => {
    if(result){
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }else {
      return {
        validateStatus: 'error',
        errorMsg: 'Please input right Filecoin address!',
      };
    }
  }

  const logOut = () => {
    deactivate();
    toggleWalletModal()
    history.push('/miningpool')
    clearLocalStorage()
    if(connector === walletconnect){
      //@ts-ignore
      connector.close();
    }
  }

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>Account</HeaderRow>
        <AccountSection>
          <YourAccount>
            <Form form={form} layout="vertical" onFinish={onFinish} hideRequiredMark> 
              {!showLinkAddr && 
                <>
                  <Form.Item label="ETH address">
                    <Input.Group compact>
                      <Input addonBefore={<img src={ETHIcon}/>} style={{ width: 'calc(100% - 90px)' }} defaultValue={`${cidStartAndEnd(account).start}...${cidStartAndEnd(account).end}` || ''} disabled/>
                      <Button style={{width: '90px'}} danger onClick={logOut}>Logout</Button>
                    </Input.Group>
                  </Form.Item>
                  {
                    filAddress && 
                    <>
                    <Form.Item label="Filecoin address">
                      <Input.Group compact>
                        <Input addonBefore={<img src={FilIcon}/>} style={{ width: 'calc(100% - 90px)' }} defaultValue={`${cidStartAndEnd(filAddress).start}...${cidStartAndEnd(filAddress).end}` || ''} disabled/>
                        <CopyToClipboard text={filAddress} onCopy={() => {message.success('Copied!')}}>
                          <Button style={{width: '90px'}} icon={<CopyIcon/>}>Copy</Button>
                        </CopyToClipboard>
                      </Input.Group>
                    </Form.Item>
                    {fileAddresStatus === 2 && <div className='contract'>
                      <div>This address has been frozen, please contact <a href="mailto:contact@sao.network">contact@sao.network</a></div>
                      </div>
                    }
                    {fileAddresStatus !== 2 && <div className="links">
                        <div><Link to={`/miner/${filAddress}`} onClick={toggleWalletModal}><DashboardIcon/>&nbsp;Mining Dashboard</Link></div>
                        <div><a target="_blank" href={`https://filfox.info/en/address/${filAddress}`}><LinkIcon/>&nbsp;View on Explorer</a></div>
                      </div>
                    }
                    </>
                  }
                  
                  {
                    !filAddress && <Form.Item>
                      <Button type="primary" block onClick={() => {localStorage.getItem('address') !== account ? getSign(library, account, false) : setShowLinkAddr(true)}}>{localStorage.getItem('address') !== account ? 'Please click "Sign" to continue' : 'Link a Filecoin address'}</Button>
                      </Form.Item>
                  }
                </>
              }
              {showLinkAddr && !cofrimLinkAddr && 
                <>
                  <Form.Item 
                    name="filAddress" 
                    label={<>Link your Filecoin address<br/><b>* This address cannot be changed after binding</b></>} 
                    rules={[{ required: true, message: 'Please enter the correct Filecoin address.'}]}
                    validateStatus={filAddressValidate.validateStatus}
                    help={filAddressValidate.errorMsg}
                  >
                    <Input onChange={handleFileAddressChange}/>
                  </Form.Item>
                  <NoticeContent>
                    <h4>Notice:</h4>
                    <ol>
                      <li>Please do not use the exchange address</li>
                      <li>Please make sure the address is the same as the one used to participate in FIL mining</li>
                      <li>if you need help, please contact support<br/><a href="mailto:contact@sao.network">contact@sao.network</a></li>
                    </ol>
                  </NoticeContent>
                  <Form.Item><Button type="primary" block onClick={confirm} disabled={!form.getFieldValue('filAddress')}>Link</Button></Form.Item>
                </>
              }
              {
                cofrimLinkAddr && 
                <ConfrimContent>
                    <p>You are binding this address</p>
                    <div className="address">{form.getFieldValue('filAddress')}</div>
                    <p>The address cannot be changed after binding.</p>
                    <div className="btns">
                      <Button block htmlType="submit" onClick={() => {setCofrimLinkAddr(false)}}>Back</Button>
                      <Button type="primary" block htmlType="submit">Continue anyway</Button>
                    </div>
                </ConfrimContent>
              }
            </Form>  
          </YourAccount>
        </AccountSection>
      </UpperSection>
    </>
  )
}
