import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { injected, SUPPORTED_WALLETS } from '../constants'
import { useLocalStorage } from 'hooks/useLocalStorage';

export default function useEagerConnect() {
    const { activate, active } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
    const [tried, setTried] = useState(false)
    const wallet_type = window.localStorage.getItem('wallet_type')
    let location = useLocation();
    useEffect(() => {
        let connector: any;
        Object.keys(SUPPORTED_WALLETS).map(key => {
          if (wallet_type === SUPPORTED_WALLETS[key].name) {
            return (connector = SUPPORTED_WALLETS[key].connector)
          }
          return true
        })
        if(connector && location.pathname !== '/'){
            activate(connector, undefined, true).catch(() => {
                setTried(true)
            })
        }else {
            setTried(true)
        }
    }, [activate, location.pathname]) // intentionally only running on mount (make sure it's only mounted once :))

    // if the connection worked, wait until we get confirmation of that to flip the flag
    useEffect(() => {
        if (active) {
            setTried(true)
        }
    }, [active])

    return tried
}
