import { useCallback, useEffect, useState } from 'react';
import { Menu, Table, Tooltip as AnTooltip, Steps, Tabs, Skeleton, Button } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment';
import numeral from 'numeral';
import { useHistory } from "react-router";
import { useMinerDetail, usePowerAndEarningChangeData, usePaymentList } from 'views/hooks';
import MiningSearch from 'components/MiningSearch';
import Cid from 'components/Cid';
import formatFIL from 'utils/formatFIL';
import formatBytes from 'utils/formatBytes';
import {ReactComponent as Back} from '../../assets/img/back.svg'
import {ReactComponent as Change} from '../../assets/img/change.svg'
import {ReactComponent as SubscribeIcon} from '../../assets/img/subscribe.svg'
import {ReactComponent as SubscribeClosedIcon} from '../../assets/img/subscribe-closed.svg'
import {ReactComponent as StartMiningIcon} from '../../assets/img/start-mining.svg'
import {ReactComponent as MiningExpirationIcon} from '../../assets/img/mining-expiration.svg'
import {ReactComponent as AccountDetailIcon} from '../../assets/img/account-detail.svg'
import {ReactComponent as NoData} from 'assets/img/nodata.svg'
import PoolActive from 'assets/img/pool-active.png'
import Oops from 'assets/img/Oops.png'

import './index.less'
const { Step } = Steps;
const { TabPane } = Tabs;

const MinerDetail = (props: RouteComponentProps<{ address: string, productId: string }>) => {
    const {
      match: {
        params: { address, productId }
      }
    } = props
    const history = useHistory();
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [refresh, setRefresh] = useState<number>(1);
    const {minerInfo, noMiner, joinedProductList, pendingProductList} = useMinerDetail(address, productId, refresh);
    const powerAndEarningChangeData = usePowerAndEarningChangeData(minerInfo?.accountId, productId);
    const paymentList = usePaymentList(address, productId);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 767px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);
    
        return () => {
          mediaQuery.removeListener(handleMediaQueryChange);
        };
      }, []);
    
    const handleMediaQueryChange = (mediaQuery: any) => {
        if (mediaQuery.matches) {
          setIsSmallScreen(true);
        } else {
          setIsSmallScreen(false);
        }
    };

    const depositColumns:ColumnsType<any> = [
        {
            title: 'Deposit Time',
            dataIndex: 'time',
            key: 'time',
            fixed: 'left',
            width: 110,
            render: (time: any) => moment(time).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Deposit Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 120,
            render: (v: any) => `${formatFIL(v)}`
        },
        {
            title: 'Stake Period',
            dataIndex: 'stakePeriod',
            key: 'stakePeriod',
            width: 110,
            render: (time: any) => moment(time).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Est. Early Bird Bonus',
            dataIndex: 'EstInterest',
            key: 'EstInterest',
            width: 140,
            render: (v: any) => `${formatFIL(v)}`
        },
        {
            title: 'Note',
            dataIndex: 'messageId',
            key: 'messageId',
            width: 180,
            render:(v: number) => <>Deposit (<Cid type="message" value={v}/>)</>
        },
    ]

    const menu = (
        <Menu>
            {pendingProductList.map((pool: any) => 
                <Menu.Item className="pending" key={pool.productId}>{pool.productName}</Menu.Item>
            )}
        </Menu>
    )

    useEffect(() => {
        if(joinedProductList.length > 0){
            
        }

    },[joinedProductList, pendingProductList])


    return (
        <div className="miner-detail">
            <MiningSearch address={address}/>
            <div className="container">
                <div className="back">
                    <div onClick={() => {history.push(`/miner/${address}`)}}><Back/>Back</div>
                    <div onClick={() => {history.push(`/miner-account-history/${address}/${productId}`)}}>
                       <AccountDetailIcon/>&nbsp;Account Details
                    </div>
                </div>
                {noMiner && 
                    <div className="no-miner content">
                        <img src={Oops} alt="" />
                        <p>No mining information found for</p>
                        <p>"{address}"</p>
                        <p><a href="mailto:contact@sao.network">if you need help, Click here.</a></p>   
                    </div>
                }
                {!noMiner && 
                    <>
                        {minerInfo.status === undefined && 
                            <Skeleton active/>
                        }
                        {minerInfo.status === 2 &&
                            <>
                                {minerInfo.joinedProductList && minerInfo.joinedProductList.filter((v: any) => productId ? v.productId === productId : true).map((miner: any) => 
                                    <div key={miner.productId} className="content miner-info">
                                        <div className="mining-data detial">
                                            <div>
                                                <div>Product</div>
                                                <div>{miner.productName}</div>
                                            </div>  
                                            <div>
                                                <div>Mining Days</div>
                                                <div>
                                                    {miner.miningDays}
                                                </div>
                                            </div>
                                            <div>
                                                <div>Est. Maturity</div>
                                                <div>{miner.closeTime && moment(miner.closeTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                                            </div> 
                                            <div>
                                                <div>
                                                    <AnTooltip placement="right" title="*Intelligent Analysis Based On Economic Model">
                                                    Est. APY&nbsp;<QuestionCircleFilled/>
                                                    </AnTooltip>
                                                </div>
                                                <div>{(miner.apy * 100).toFixed(2)}%</div>
                                            </div>
                                            <div>
                                                <div> 
                                                    <AnTooltip placement="right" title="Pledge + Pending Assets + Available Rewards - Gas Fee">
                                                    Assets&nbsp;<QuestionCircleFilled/>
                                                    </AnTooltip>
                                                </div>
                                                
                                                <div>{formatFIL(miner.asset)}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="data-content">
                                    <div className="content">
                                        <div className="first">
                                            <div>
                                                <div>
                                                    <AnTooltip placement="right" title="Initial Pledge + Deal Collateral">
                                                        Adj. Power&nbsp;<QuestionCircleFilled/>
                                                    </AnTooltip>
                                                </div>
                                                <div>
                                                    {formatBytes(minerInfo.powerAdj)} ({formatFIL(minerInfo.staked)})
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <AnTooltip placement="right" title="Profits to be withdrawed">
                                                        Available Rewards&nbsp;<QuestionCircleFilled/>
                                                    </AnTooltip>
                                                </div>
                                                <div>{formatFIL(minerInfo.availableEarning)}</div>
                                            </div>
                                        </div>
                                        <div className="second">
                                            <div>
                                                <div>Pending Assets</div>
                                                <div>{formatFIL(minerInfo.unStaked)}</div>
                                            </div> 
                                            <div>
                                                <div>
                                                    Gas Fee
                                                </div>
                                                <div>{formatFIL(minerInfo.gasFee)}</div>
                                            </div>
                                            <div>
                                                <div>Locked Rewards</div>
                                                <div>{formatFIL(minerInfo.locked)}</div>
                                            </div>
                                            <div>
                                                <div>Total Rewards</div>
                                                <div>{formatFIL(minerInfo.totalEarning)}</div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div className="change">
                                    <div className="power">
                                        <div className="title">
                                            <Change/> Power Change
                                        </div>  
                                        <div className="content">
                                            {powerAndEarningChangeData.length > 0 &&
                                                <div className="chart">
                                                    <ResponsiveContainer width="99%" height="100%" debounce={1}>
                                                        <LineChart
                                                            data={powerAndEarningChangeData}
                                                            margin={{
                                                                top: 15,
                                                                right: 20,
                                                                left: 5,
                                                                bottom: 5,
                                                            }}
                                                        >
                                                            <CartesianGrid vertical={false}/>
                                                            <XAxis dataKey="time" tickFormatter={(time) => moment(time).format('MM-DD')}/>
                                                            <YAxis width={80} tickFormatter={(v) => formatBytes(v)}/>
                                                            <Tooltip labelFormatter={(time) => moment(time).format('YYYY-MM-DD')} formatter={(value: any, name: any) => [formatBytes(value), name]}/>
                                                            <Legend verticalAlign="bottom" wrapperStyle={{ fontSize: '14px', bottom : '10px' }}/>
                                                            <Line type="monotone" dataKey="power" name="Power" stroke="#5ab1ef" dot={false}/>
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            }
                                            {powerAndEarningChangeData.length === 0 && 
                                                <div className="nodata">
                                                    <NoData/>
                                                    <div>No data</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="earning">
                                        <div className="title">
                                            <Change/> Earning Change
                                        </div>  
                                        <div className="content">
                                            {powerAndEarningChangeData.length > 0 &&
                                                <div className="chart">
                                                    <ResponsiveContainer width="99%" height="100%" debounce={1}>
                                                        <LineChart
                                                            data={powerAndEarningChangeData}
                                                            margin={{
                                                                top: 15,
                                                                right: 20,
                                                                left: 5,
                                                                bottom: 5,
                                                            }}
                                                        >
                                                            <CartesianGrid vertical={false}/>
                                                            <XAxis dataKey="time" tickFormatter={(time) => moment(time).format('MM-DD')}/>
                                                            <YAxis width={50} tickFormatter={(v) => `${numeral(formatFIL(v)).format('0,0')} FIL`}/>
                                                            <Tooltip labelFormatter={(time) => moment(time).format('YYYY-MM-DD')} formatter={(value: any, name: any) => [`${formatFIL(value)}`, name]}/>
                                                            <Legend verticalAlign="bottom" wrapperStyle={{ fontSize: '14px', bottom : '10px' }}/>
                                                            <Line type="monotone" dataKey="earning" name="Earning" stroke="#19d4ae" dot={false}/>
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            }
                                            {powerAndEarningChangeData.length === 0 && 
                                                <div className="nodata">
                                                    <NoData/>
                                                    <div>No data</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </> 
                        }
                        {minerInfo.status !== undefined && minerInfo.status !== 2 &&
                            <div className="pending-data">
                                <div className="content">
                                    <div className="pool">
                                        <img src={PoolActive} alt="" />
                                        <p>
                                        {minerInfo?.pendingProductList && minerInfo?.pendingProductList[0]?.productName}
                                        </p>
                                    </div>
                                    {!isSmallScreen && 
                                        <Steps direction="horizontal">
                                            <Step status="finish" title={<b>Public Offering</b>} icon={<SubscribeIcon className="active"/>} />
                                            <Step status={minerInfo?.pendingProductList && minerInfo?.pendingProductList[0]?.status === 1 ? 'finish': 'process'} title={<><b>Closing Date</b><br/>Confirm Shares</>} icon={<SubscribeClosedIcon />} />
                                            <Step status="process" title={<><b>Start Mining</b><br/>Withdrawable</>} icon={<StartMiningIcon />} />
                                            <Step status="wait" title={<><b>Maturity Date</b><br/>Options at maturity: (i) redemption or (ii) reinvestment</>} icon={<MiningExpirationIcon />} />
                                        </Steps>
                                    }
                                    {isSmallScreen && 
                                        <Steps direction="vertical">
                                            <Step status="finish" title={<b>Public Offering</b>} icon={<SubscribeIcon className="active"/>} />
                                            <Step status={minerInfo?.pendingProductList && minerInfo?.pendingProductList[0]?.status === 1 ? 'finish': 'process'} title={<><b>Closing Date</b><br/>Confirm Shares</>} icon={<SubscribeClosedIcon />} />
                                            <Step status="process" title={<><b>Start Mining</b><br/>Withdrawable</>} icon={<StartMiningIcon />} />
                                            <Step status="wait" title={<><b>Maturity Date</b><br/>Options at maturity: (i) redemption or (ii) reinvestment</>} icon={<MiningExpirationIcon />} />
                                        </Steps>
                                    }   
                                    <div className="title">
                                    Deposit History
                                    </div>  
                                    <Table columns={depositColumns} dataSource={paymentList} scroll={{ y: 400 }}/>
                                    <h3>Mining Instructions</h3>
                                    <ol>
                                        <li>The account which you deposit from automatically becomes your "Mining Account".</li>
                                        <li>All deposits from your "Mining Account" contributes to your total shares during Public Offering period. Your shares are finalized at the end of Public Offering period</li>
                                        <li>Mining rewards will be distributed to your Mining Account. Initial pledges and deal collateral will be returned back to your Mining Account after sector lifecycle ends.</li>
                                        <li>If your deposits exceeds product's remaining quota, the excess amount will be refunded along with your first mining reward distribution.</li>
                                        <li>Deposit FIL to earn early bird bonus (APY {minerInfo?.pendingProductList[0]?.interestApy*100}%) during the Public Offering Period (POP). As the Est. Early Bird Bonus are for reference, if the funding target is reached or exceeded ahead of schedule, the actual Early Bird Bonus shall be calculated by the actual days of POP.</li>
                                    </ol>
                                    <p>
                                        IMPORTANT:<br/>
                                        ALWAYS deposit from self-owned account. DO NOT transfer from any accounts in centralized platforms incl. Exchanges, Mining pools, or you may LOSE ALL rewards and pledges.
                                    </p>
                                </div>
                            </div>
                        }   
                    </>
                }
            </div>
        </div>
    )
}

export default MinerDetail