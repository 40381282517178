import React from 'react'
import { isAddress } from 'utils'

export function cidStartAndEnd (value: any) {
  const chars = value.split('')
  if (chars.length <= 9) return value
  const start = chars.slice(0, 8).join('')
  const end = chars.slice(chars.length - 8).join('')
  return {
    value,
    start,
    end
  }
}

const ShortCid = ({value, title, style, type, ...props}: any) => {
  style = Object.assign({}, {
    textDecoration: 'none'
  }, style)
  const {start, end} = cidStartAndEnd(value)
  return (
    <a href={`https://filfox.info/en/${type}/${value}`} target="_blank" style={style} {...props}>
      <span>{start}</span>
      <span className='o-20'>...</span>
      <span>{end}</span>
    </a>
  )
}

const Cid = ({value, children, type}: any) => {
  if (!value && !children) return null

  let cidStr = value || children
  if (cidStr['/']) {
    cidStr = cidStr['/']
  }
  if (typeof cidStr !== 'string') {
    throw new Error('Unexpected CID value')
  }
  return (
    <ShortCid value={cidStr} type={type} className='monospace f7' style={{color: '#0090FF'}}/>
  )
}

export default Cid