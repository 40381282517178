import React, { useEffect } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { Button } from 'antd'
import styled from 'styled-components'
import { useHistory } from "react-router";
import { useWalletModalToggle } from '../../state/application/hooks'
import { shortenAddress } from '../../utils'
import { ChainLogo } from '../CurrencyLogo'
import { walletconnect } from '../../constants'
import { clearLocalStorage, getSign } from '../../utils/txSign'
import WalletModal from '../WalletModal'
import { NetworkContextName } from '../../constants'
import {ReactComponent as WalletIcon} from 'assets/img/wallet.svg'
import './index.less';

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  color: #000;
`


const AccountWraper = styled.span`
  margin-left: 10px;
`
// we want the latest one to come first, so return negative if a is after b
// function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
//   return b.addedTime - a.addedTime
// }

const SOCK = (
  <span role="img" aria-label="has socks emoji" style={{ marginTop: -4, marginBottom: -4 }}>
    🧦
  </span>
)

function Web3StatusInner({role}: any) {
  const { chainId, account, error } = useWeb3React()

  const toggleWalletModal = useWalletModalToggle()

  if (account) {
    return (
      <div className=''>
          { role != ''? 
          <div className="congratulation"><span className='title'>Congratulations!</span><br /><span className="content">It's verified that you have joined in the SAO joint mining. Now you are granted the role tags.</span></div>
              : ''}
          <Button size="large" className='account_btn'>
            <WalletIcon fill='#fff' height={20}/>
            <AccountWraper>{account}</AccountWraper>
          </Button>
      </div>
    )
  } else if (error) {
    return (
      <Button size="large" danger>
        <Text>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</Text>
      </Button>
    )
  } else {
    return (
      <Button size="large" type="primary" onClick={toggleWalletModal} className="connect-btn">
        Connect Wallet
      </Button>
    )
  }
}

export default function DiscordWeb3Status({role} : any) {
  const { active, deactivate, account, connector } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)
  const history = useHistory();
  const toggleWalletModal = useWalletModalToggle()
    console.log("role", role)

  if (!contextNetwork.active && !active) {
    return null
  }

  const goToJoint = () => {
    history.push('/miningpool')
  }

  const logOut = () => {
    deactivate();
    clearLocalStorage()
    if(connector === walletconnect){
      //@ts-ignore
      connector.close();
    }
  }


  return (
    <div className='discord-web3-status'>
      <Web3StatusInner role={role}/>
      <WalletModal />
      { account  ? 
      <Button size="large" onClick={logOut} className={ role == '' ? 'verify-btn' : 'verify-btn-only'}>
          Verify Another Address
      </Button>
          : ''
      }
      { role == '' && account ? 
      <Button size="large" onClick={goToJoint} className='verify-btn'>
          Joint Mining
      </Button> : ''}
    </div>
  )
}
