import {CopyToClipboard} from 'react-copy-to-clipboard';
import moment from 'moment';
import { message, Modal, Divider } from 'antd';
import { useFileDetail } from '../hooks';
import { bytesToSize } from 'utils/formatBytes';
import {ReactComponent as Copy} from 'assets/img/copy.svg'

import './index.less'

export default function FileInfoModal({visible, handleOk, handleCancel, fileId}: any) {
    const data = useFileDetail(fileId);
    const start = data?.ipfsHash ? data?.ipfsHash.slice(0, 6) : ''
    const end = data?.ipfsHash ? data?.ipfsHash.slice(data.ipfsHash.length - 6) : ''
    return (
        <Modal width={348} className="fileInfoModal" title={`Item Info`} footer={null} visible={visible} onOk={handleOk} onCancel={() => {handleCancel();}}>
           <div className="content">
                <h2>General</h2>
                <div className='info'>
                    <div>
                        <div>Name</div>
                        <div>{data.filename}</div>
                    </div>
                    <div>
                        <div>Type</div>
                        <div>{data.format}</div>
                    </div>
                    <div>
                        <div>Size</div>
                        <div>{bytesToSize(data.size)}</div>
                    </div>
                    <div>
                        <div>Last Modified</div>
                        <div>{data.lastModifiedAt && moment(data.lastModifiedAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                    </div>
                </div>
                <Divider />
                <h2>More</h2>
                <div className='info'>
                    <div>
                        <div>App</div>
                        <div>{data.appName}</div>
                    </div>
                    <div>
                        <div>IPFS Hash</div>
                        <div>
                            <CopyToClipboard text={data?.ipfsHash}
                                onCopy={() => {message.success('Copied!')}}>
                                    <div className='copyhash'>
                                        <span>{start}</span>
                                        <span className='o-20'>...</span>
                                        <span>{end}</span>
                                        <span className="copyIcon"><Copy/></span>
                                    </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div>
                        <div>CID</div>
                        <div>{data.cid}</div>
                    </div>
                    <div>
                        <div>Storage Providers</div>
                        <div>{data.storageProvider}</div>
                    </div>
                    <div>
                        <div>Access</div>
                        <div>R/W</div>
                    </div>
                </div>
                {data.preview &&
                    <>
                        <Divider />
                        <h2>Preview</h2>
                        {data.preview && <img src={data.preview}/>}
                    </>
                }                  
           </div>
        </Modal>
    )
}