import { useState } from 'react';
import { Modal, Select, Table, Button, Tabs, Progress, Space, Divider, Input, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {  PlusCircleFilled, CaretDownOutlined, SearchOutlined, FileOutlined } from '@ant-design/icons';
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment';
import numeral from 'numeral';
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useMinerDetail, useAppList, useFileList, useAppOverview, deleteApp, deleteFile, getFile, useAppNameList } from 'views/hooks';
import { bytesToSize, bytesToSize2 } from 'utils/formatBytes';
import CreateAppModal from './CreateAppModal';
import FileInfoModal from './FileInfoModal';
import {ReactComponent as LearMore} from 'assets/img/learnmore.svg'
import {ReactComponent as EditIcon} from 'assets/img/edit.svg'
import {ReactComponent as DeleteIcon} from 'assets/img/delete.svg'
import {ReactComponent as DownloadIcon} from 'assets/img/download.svg'
import {ReactComponent as ZipIcon} from 'assets/img/zip.svg'
import {ReactComponent as JpgIcon} from 'assets/img/jpg.svg'
import {ReactComponent as PdfIcon} from 'assets/img/pdf.svg'
import {ReactComponent as Mp4Icon} from 'assets/img/mp4.svg'
import {ReactComponent as PngIcon} from 'assets/img/png.svg'
import {ReactComponent as DocIcon} from 'assets/img/doc.svg'
import {ReactComponent as FileIcon} from 'assets/img/file.svg'
import {ReactComponent as FileTypeIcon} from 'assets/img/file-type.svg'

import './index.less'

const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;

const DataStore = (props: RouteComponentProps<{ address: string, productId: string | undefined }>) => {
    const {
      match: {
        params: { address, productId }
      }
    } = props
    const { account, library } = useActiveWeb3React()
    const [appRefresh, setAPPRefresh] = useState<number>(1);
    const [filerefresh, setFileRefresh] = useState<number>(1);
    const [appOverviewRefresh, setAppOverviewRefresh] = useState<number>(1);
    const [appId, setAppId] = useState<string>('');
    const [fileId, setFileId] = useState<string>('');
    const [filename, setFileName] = useState('')
    const [fromApp, setFromApp] = useState('')
    const [appPagination, setAppPagination] = useState<any>({current: 1, pageSize: 10})
    const [filePagination, setFilePagination] = useState<any>({current: 1, pageSize: 10})
    const [type, setType] = useState<any>('')
    const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
    const [showFileInfoModal, setShowFileInfoModal] = useState<boolean>(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])
    const appOverview = useAppOverview(appOverviewRefresh);
    const appData = useAppList(appPagination, appRefresh)
    const fileData = useFileList(filePagination, filerefresh, filename, fromApp)
    const appNames = useAppNameList(appRefresh);

    const handleAppTableChange = (_pagination: any) => {
        setAppPagination(_pagination)
    }

    const onChange = (key: string) => {
        console.log(key);
        if(key === '2'){
            setFileRefresh(filerefresh + 1);
        }
        if(key === '1'){
            setAPPRefresh(appRefresh + 1);
        }
    };


    const showDeleteAppConfirm = (appId: string) => {
        confirm({
          title: 'Deleting application',
          content: 'All files in the application will be deleted, Are you sure you want to delete this application?',
          okText: 'Yes, Delete',
          wrapClassName: 'delete-modal',
          icon: <></>,
          width: 348,
          closable: true,
          onOk: async() => {
            const res: any = await deleteApp(appId, account, library);
            if(res && res.code === '200'){
                message.success('Delete Success!');
                if((appData.total - 1)%10 === 0){
                    setAppPagination({current: appPagination.current > 1 ? appPagination.current -1 : 1, pageSize: 10})
                }
                setAPPRefresh(appRefresh + 1);
                setAppOverviewRefresh(appOverviewRefresh + 1);
            }else{
                message.error('Delete Failed!');
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        });
    };
    const showDeleteFileConfirm = (ids: string[]) => {
        confirm({
          title: 'Deleting item',
          content: 'Are you sure you want to delete this item?',
          okText: 'Yes, Delete',
          wrapClassName: 'delete-modal',
          icon: <></>,
          width: 348,
          closable: true,
          onOk: async() => {
            const res: any = await deleteFile(ids, account, library);
            if(res && res.code === '200'){
                message.success('Delete Success!');
                setFilePagination({current: 1, pageSize: 10})
                setFileRefresh(appRefresh + 1);
                setAppOverviewRefresh(appOverviewRefresh + 1);
                setSelectedRowKeys([])
            }else{
                message.error('Delete Failed!');
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        });
    };

    const downloadFile = async(id: string, appId: string, apiKey: string, filename: string) => {
        const res: any = await getFile(id, appId, apiKey, account, library);
        if(res && res.size){
            var a = document.createElement("a");
            a.href = window.URL.createObjectURL(res);
            a.download = filename;
            a.click();
        }else{
            message.error('Download Failed!');
        }
    }

    const batchDownloadFiles = (ids: string[]) => {
        console.log('ids:', ids);
        for(let i = 0; i < ids.length; i ++){
            const index = fileData.list.findIndex((data: any) => data.id === ids[i]);
            const file = fileData.list[index];
            downloadFile(file.id, file.appId, file.apiKey, file.filename);
        }
    }

    const applicationColumns:ColumnsType<any> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 110,
        },
        {
            title: 'Name',
            dataIndex: 'applicationName',
            key: 'applicationName',
            width: 120,
        },
        {
            title: 'API Key',
            dataIndex: 'apiKey',
            key: 'apiKey',
            width: 120,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 80,
            render: (type: number) => type === 1 ? 'Personal': 'Business'
        },
        {
            title: 'Usage',
            dataIndex: 'usage',
            key: 'usage',
            width: 100,
            render: (v: number) => bytesToSize(v)
        },
        {
            title: 'Quota',
            dataIndex: 'quota',
            key: 'quota',
            width: 110,
            render: (v: number) => bytesToSize(v)
        },
        {
            title: '',
            width: 100,
            dataIndex: 'id',
            key: 'id',
            render:(id: string) => <Space split={<Divider type="vertical" />}>
                <Button type='link' onClick={() => {setAppId(id);setShowCreateAppModal(true)}} icon={<span className='anticon anticon-plus-circle'><EditIcon/></span>}></Button>
                <Button type='link' onClick={() => {showDeleteAppConfirm(id)}} icon={<span className='anticon anticon-plus-circle'><DeleteIcon/></span>}></Button>
            </Space>
        },
    ]

    const myFileColumns:ColumnsType<any> = [
        {
            title: 'File Name',
            dataIndex: 'filename',
            key: 'filename',
            fixed: 'left',
            width: 120,
            render: (filename: string, row: any) => <div className='filename'>{getFileIcon(row.format)}<Tooltip title={filename}><p>{filename}</p></Tooltip><Button type='link' onClick={() => {setFileId(row.id); setShowFileInfoModal(true)}} icon={<span className='anticon anticon-plus-circle'><FileIcon/></span>}></Button></div>
        },
        {
            title: 'Last Modified',
            dataIndex: 'lastModifiedAt',
            key: 'lastModifiedAt',
            width: 110,
            render: (time: any) => moment(time).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            width: 100,
            render: (v: number) => bytesToSize(v)
        },
        {
            title: 'From App',
            dataIndex: 'appName',
            key: 'appName',
            width: 80,
        },
        {
            title: '',
            width: 80,
            dataIndex: 'id',
            key: 'id',
            render:(id: string, row: any) => <Space split={<Divider type="vertical" />}>
                    <Button type='link' onClick={() => {downloadFile(id, row.appId, row.apiKey, row.filename)}} icon={<span className='anticon anticon-plus-circle'><DownloadIcon/></span>}></Button>
                    <Button type='link' onClick={() => {showDeleteFileConfirm([id])}} icon={<span className='anticon anticon-plus-circle'><DeleteIcon/></span>}></Button>
                </Space>
        },
    ]

    const getFileIcon = (type: string) => {
        switch(type){
            case 'zip':
                return <ZipIcon/>
            case 'image/jpg':
            case "image/jpeg":
                return <JpgIcon/>
            case 'application/pdf':
                return <PdfIcon/>
            case 'mp4':
                return <Mp4Icon/>
            case 'image/png':
                return <PngIcon/>
            case 'application/msword':
                return <DocIcon/>
            default: 
                return <FileOutlined />            
        }
    }

    const handleFileNameChange = (e: any) => {
        setFileName(e.target.value)
    }

    const handleApplicationChange = (value: string) => {
        setFromApp(value)
    }

    const onSelectChange = (values: any) => {
        console.log("selectedRowKeys:", values)
        setSelectedRowKeys(values)
    }

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
        <div className="data-store">
            <CreateAppModal visible={showCreateAppModal} costPerG={appOverview.costPerG} appId={appId} setRefresh={() => {setAPPRefresh(appRefresh + 1); setAppOverviewRefresh(appOverviewRefresh + 1);}} handleOk={() => {}} handleCancel={() => {setShowCreateAppModal(false)}}/>
            <FileInfoModal visible={showFileInfoModal} fileId={fileId} handleOk={() => {}} handleCancel={() => {setShowFileInfoModal(false)}}/>
            <Tabs 
                defaultActiveKey="1"
                tabBarExtraContent={
                    <div className='storage'>Storage: {appOverview?.totalUsed ? bytesToSize2(appOverview.totalUsed) : '0 G'}/{appOverview?.totalQuota ? bytesToSize2(appOverview.totalQuota) : '0 G'}, {!appOverview?.totalUsed ? 0 : (appOverview.totalUsed/appOverview.totalQuota < 0.00001 ? '<0.01':numeral(appOverview.totalUsed/appOverview.totalQuota * 100).format('0,0.00'))}% used
                    <Progress trailColor="#E0E0E0" strokeColor="#40BA5B" strokeWidth={17} percent={!appOverview?.totalUsed ? 0 : appOverview.totalUsed/appOverview.totalQuota * 100}/>
                    </div>
                }
                onChange={onChange}
            >
                <TabPane tab="Application" key="1">
                    <div className='account-detail-head'>
                        <div>{account && <Button icon={<PlusCircleFilled />} type="default" onClick={() => {setAppId('');setShowCreateAppModal(true)}}>Create</Button>}</div>
                        <div><a href='https://docs.sao.network/products/data-store' target="_blank"><Button type='link' icon={<span className='anticon anticon-plus-circle'><LearMore/></span>}>Learn more</Button></a></div>
                    </div>
                    <Table columns={applicationColumns} dataSource={appData.list} pagination={{...appPagination, total: appData.total}} scroll={{ y: 1000 }} onChange={handleAppTableChange} rowKey={'id'}/>
                </TabPane>
                <TabPane tab="My File" key="2">
                    <div className='account-detail-head'>
                        <div>
                            {hasSelected ? <>
                                You have selected {selectedRowKeys.length} files.&nbsp;&nbsp;&nbsp;&nbsp;
                                <Space split={<Divider type="vertical" />}>
                                    <Button type='link' onClick={() => {batchDownloadFiles(selectedRowKeys)}} icon={<span className='anticon anticon-plus-circle'><DownloadIcon/></span>}></Button>
                                    <Button type='link' onClick={() => {showDeleteFileConfirm(selectedRowKeys)}} icon={<span className='anticon anticon-plus-circle'><DeleteIcon/></span>}></Button>
                                </Space>
                            </> : `${fileData.total} files`}
                        </div>
                        <div>
                            <Input prefix={<SearchOutlined style={{fontSize: '20px'}}/>} placeholder=' Search File Name' onChange={handleFileNameChange}></Input>
                            <Select defaultValue="all" suffixIcon={<CaretDownOutlined style={{color: '#000'}}/>} onChange={handleApplicationChange}>
                                <Option value="all">All Applications</Option>
                                {appNames.length > 0 && appNames.map((name: string) => <Option key={name} value={name}>{name}</Option>)}
                            </Select>
                            <Button type='link' icon={<span className='anticon anticon-plus-circle'><LearMore/></span>}><a className='learnmore' href='https://docs.sao.network/products/data-store' target="_blank">Learn more</a></Button>
                        </div>
                    </div>
                    <Table rowSelection={rowSelection} columns={myFileColumns} dataSource={fileData.list} pagination={false} scroll={{ y: 1000 }} rowKey={'id'}/>
                    {filePagination.current * 10 < fileData.total && <Button onClick={() => {setFilePagination({current: filePagination.current + 1, pageSize: 10})}} className='loadmore'>{`<<     Load more   >>`}</Button>}
                </TabPane>
            </Tabs>
        </div>
    )
}

export default DataStore