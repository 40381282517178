import { InjectedConnector } from "@web3-react/injected-connector"

const injected = new InjectedConnector({
    supportedChainIds: [1, 4, 56, 97]
});
export const setupEthMainnet = async (chainId: number) => {
    if (chainId === 1) {
        return
    }
    try {
        await injected.getProvider().then((provider: any) => {
            provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: `0x1`,
                }]
            })
            return true
        })
    } catch (err) {
        console.log(err)
        return false
    }
}

export const setupEthTestnet = async () => {
    try {
        await injected.getProvider().then((provider: any) => {
            provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: `0x4`,
                }]
            })
            return true
        })
    } catch (err) {
        console.log(err)
        return false
    }
}

export const setupBscMainnet = async (chainId: number) => {
    if (chainId === 56) {
        return;
    }
    try {
        await injected.getProvider().then((provider: any) => {
            provider.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    //@ts-ignore
                    chainId: `0x${'38'.toString(16)}`,
                    chainName: 'Binance Smart Chain Mainnet',
                    nativeCurrency: {
                        name: 'BNB',
                        symbol: 'bnb',
                        decimals: 18,
                    },
                    rpcUrls: [
                        "https://bsc-dataseed1.ninicoin.io",
                        "https://bsc-dataseed1.defibit.io",
                        "https://bsc-dataseed.binance.org"
                    ],
                    blockExplorerUrls: ['https://bscscan.com/']
                }]
            })
            return true
        })
    } catch (err) {
        console.log(err)
        return false
    }
}

export const setupBscTestnet = async () => {
    try {
        await injected.getProvider().then((provider: any) => {
            provider.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    //@ts-ignore
                    chainId: `0x${'61'.toString(16)}`,
                    chainName: 'Binance Smart Chain Testnet',
                    nativeCurrency: {
                        name: 'BNB',
                        symbol: 'bnb',
                        decimals: 18,
                    },
                    rpcUrls: [
                        "https://data-seed-prebsc-1-s1.binance.org:8545/",
                        "https://data-seed-prebsc-2-s1.binance.org:8545/",
                        "https://data-seed-prebsc-1-s2.binance.org:8545/"
                    ],
                    blockExplorerUrls: ['https://bscscan.com/']
                }]
            })
            return true
        })
    } catch (err) {
        console.log(err)
        return false
    }
}

export const networkListener = () => {
    if (window.ethereum) {
        //@ts-ignore
        window.ethereum.on('chainChanged', (_chainId: number) => {
            let currentChainId = localStorage.getItem("chainId")
            if (!currentChainId || parseInt(currentChainId) != _chainId) {
                localStorage.setItem("chainId", _chainId + '')
                window.location.reload()
            }
        });
        //@ts-ignore
        window.ethereum.on('disconnect', (_err: any) => window.location.reload());
        //@ts-ignore
        window.ethereum.on('accountsChanged', (_err: any) => window.location.reload());
    }
}
