import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { getSign, clearLocalStorage } from 'utils/txSign';
import { get, request } from 'utils/request';
import { API_URL, walletconnect } from 'constants/index';
import { AppDispatch, AppState } from '../index'
import { getFilAddress, getDepositResult } from './actions'


export function useFetchFilAddress(): () => void  {
  const { account, deactivate, library, connector } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const fetchData = useCallback(async() => {
      const sign = await getSign(library, account, false)
      get(`${API_URL}/sao/api/userDetail/getFilAddress`, sign).then((res: any) => {
          if(res && res.code === '200'){
            dispatch(getFilAddress(res.data))
          }else if(res && res?.code.includes('signature')){
            // setForceSign(true)
            deactivate();
            clearLocalStorage()
            if(connector === walletconnect){
              //@ts-ignore
              connector.close();
            }
          }
      })
  }, [dispatch, account, library])
  // useEffect(() => {
  //   if(account && library){
  //     fetchData()
  //   }
  // },[account, library, forceSign])
  return fetchData
}

export function useFetchDepositResult(): (startTime: string) => void  {
  const { account, deactivate, library, connector } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const fetchData = useCallback(async(startTime: string) => {
      const sign = await getSign(library, account, false)
      get(`${API_URL}/sao/api/userDetail/pollingDepositResult?startTime=${startTime}`, sign).then((res: any) => {
          if(res && res.code === '200'){
            dispatch(getDepositResult(res.data))
          }else if(res && res?.code.includes('signature')){
            deactivate();
            clearLocalStorage()
            if(connector === walletconnect){
              //@ts-ignore
              connector.close();
            }
          }
      })
  }, [dispatch, account, library])
  return fetchData
}

export function useDepositResult(): string {
  const depositID = useSelector((state: AppState) => state.account.depositID)
  return depositID
}
export function useFilAddress(): string {
  const address = useSelector((state: AppState) => state.account.filAddress)
  return address
}
export function useFilAddressStatus(): number {
  const status = useSelector((state: AppState) => state.account.status)
  return status
}
