import { useState, useRef, useEffect } from 'react'
import { useHistory } from "react-router";
import { Input, Drawer } from 'antd';
import { useLocation } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useToggle } from 'hooks/useToggle';
import {ReactComponent as Delete} from '../../assets/img/delete.svg'
import './index.less';
const { Search } = Input;


const MiningSearch = ({address}: any) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const history = useHistory();
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [searchText, setSearchText] = useState<string>('')
    const [visible, setVisible] = useState<boolean>(false)
    const [miningAddress, setMiningAddress] = useLocalStorage("miningAddress", []);
    const [open, toggle] = useToggle(false)
    const ref = useRef()
    let location = useLocation();

    useOnClickOutside(ref, open ? toggle : undefined)

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 767px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);
    
        return () => {
          mediaQuery.removeListener(handleMediaQueryChange);
        };
      }, []);
    
      const handleMediaQueryChange = (mediaQuery: any) => {
        if (mediaQuery.matches) {
          setIsSmallScreen(true);
        } else {
          setIsSmallScreen(false);
        }
      };
    

    const handleChange = (e: any) => {
        setSearchText(e.target.value)
    }

    const handleEnter = (e: any) => {
        if(e && e.target.value){
            let arr = [...miningAddress]
            arr.unshift(e.target.value)
            setMiningAddress(arr)
            history.push(`/miner/${e.target.value}`)
        }
    }

    const deleteAddress = (val: any) => {
        let arr = [...miningAddress]
        var index = arr.indexOf(val); 
        if (index > -1) { 
            arr.splice(index, 1); 
        }
        setMiningAddress(arr)
    }

    return (
        <div className="mining-search">
            <div className="content">
                <div>{location.pathname.includes('miner') ? 'Mining Dashboard' : 'Joint Mining'}</div>
                <div className="search-box">
                    {/* {!isSmallScreen && 
                        <Search 
                            placeholder="Please enter FIL address to view mining details" 
                            onChange={handleChange}
                            onPressEnter={handleEnter} 
                            onSearch={handleEnter}
                            onFocus={toggle}
                            defaultValue={address}
                            allowClear
                        />
                    }
                    {
                        isSmallScreen && <SearchOutlined onClick={() => {setVisible(true)}} style={{ fontSize: '30px'}}/>
                    }
                    {open &&
                        <div className="dropdown" ref={ref as any}>
                            <div className="dropheader">
                                <div>Search History</div>
                                <div onClick={() => {setMiningAddress([])}}>
                                    <Delete fill="#000"/>
                                    &nbsp;Delete all
                                </div>
                            </div>
                            {
                                miningAddress.map((adr: string, i: number) => 
                                    <div key={i} className="item" onClick={() => {toggle();history.push(`/miner/${adr}`);setShowDropdown(false)}}>
                                        <div>{adr}</div>
                                        <div onClick={() => {deleteAddress(adr)}}><Delete /></div>
                                    </div>
                                )
                            }
                        </div>
                    } */}
                </div>
            </div>
            <Drawer
                placement="right"
                closable={true}
                visible={visible}
                className='search-drawer'
                getContainer={false}
                onClose={() => {setVisible(false)}}
                style={{ position: 'absolute' }}
            >
                <div className="search-box">
                    {/* <Search 
                        placeholder="Please enter FIL address to view mining details" 
                        onChange={handleChange}
                        onPressEnter={handleEnter} 
                        onSearch={handleEnter}
                        onFocus={toggle}
                    /> */}
                </div>
            </Drawer>  
            {
                open && isSmallScreen &&  
                <div className="dropdown" ref={ref as any}>
                    <div className="dropheader">
                        <div>Search History</div>
                        <div onClick={() => {setMiningAddress([])}}>
                            <Delete fill="#000"/>
                            &nbsp;Delete all
                        </div>
                    </div>
                    {miningAddress.map((adr: string, i: number) => 
                        <div key={adr} className="item" onClick={() => {toggle();history.push(`/miner/${adr}`);setShowDropdown(false)}}>
                            <div>{adr}</div>
                            <div onClick={() => {deleteAddress(adr)}}><Delete /></div>
                        </div>
                    )}
                </div>  
            }
        </div>
    )
}

export default MiningSearch;