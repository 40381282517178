import numeral from 'numeral';

export default function formatBytes(bytes: number, decimals = 2) {
    if (!bytes) return '0 TiB';
    if(bytes == 0 || bytes == null){
        return '0 TiB'
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return numeral(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))).format("0,0.00") + ' ' + sizes[i];
}

export function formatBytesNumber(bytes: number, decimals = 2) {
    if (!bytes) return '0';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return numeral(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))).format("0,0.00");
}

export function bytesToSize(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return numeral(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))).format("0,0.00") + ' ' + sizes[i];
}
export function bytesToSize2(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return numeral(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))).format("0,0.00") + ' ' + sizes[i];
}