import { useState, useEffect } from 'react';
import { message, Modal, Button, Radio, Form, Input, Slider } from 'antd';
import type { SliderMarks } from 'antd/lib/slider';
import numeral from 'numeral';
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { createApp, updateApp, useAppDetail } from '../hooks';
import { bytesToSize } from 'utils/formatBytes';
import {ReactComponent as PersonalIcon} from 'assets/img/personal.svg'
import {ReactComponent as BusinessIcon} from 'assets/img/business.svg'

import './index.less'

export default function CreateAppModal({appId, costPerG, visible, handleOk, handleCancel, setRefresh}: any) {
    const [form] = Form.useForm();
    const [spaceType, setSpaceType] = useState<number>(0)
    const [space, setSpace] = useState<number>(0)
    const {account, library} = useActiveWeb3React()
    const appData = useAppDetail(appId)
    const handleTypeChange = (e: any) => {
        setSpaceType(e.target.value)
    }

    const onFinish = async(values: any) => {
        console.log('values:', values)
        let params = {
            ...appData,
            quota: values.space * 1024 * 1024 * 1024,
            applicationName: values.applicationName,
            type: spaceType,
            invitationCode: values.invitationCode
        }
        if(!values.invitationCode) delete params.invitationCode
        const res: any = appId ?  await updateApp(params, account, library) : await createApp(params, account, library);
        if(res && res.code === '200'){
            appId ? message.success('Update success!') : message.success('Create success!');
            setRefresh();
            handleCancel();
        }else{
            message.error(res.msg);
        }
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    const handleSliderChange = (value: number) => {
        setSpace(value)
    }

    const marks: SliderMarks = {
        0: '0G',
        [spaceType === 1 ? 15 : 300]: `${spaceType === 1 ? 15 : 300}G`,
    };
    
    const clearForm = () => {
        setSpaceType(0)
        setSpace(0)
        form.resetFields()
    }
    useEffect(() => {
        if(Object.keys(appData).length > 0 && appId){
            let values = {...appData};
            const num = Number(parseFloat(bytesToSize(appData.quota)))
            values['space'] = num;
            setSpace(num);
            form.setFieldsValue(values)
            setSpaceType(appData.type)
        }
    }, [appData, appId])

    useEffect(() => {
        if(!appId){
            clearForm()
        }
    },[appId])

    return (
        <Modal width={348} className="createAppModal" title={appId ? 'Update' : (!spaceType ? 'Please select space type' : `Create ${spaceType === 1 ? 'personal' : 'business' } space`)} footer={null} visible={visible} onOk={handleOk} onCancel={() => {
            if(!appId){
                clearForm()
            }
            handleCancel();
        }}>
           <div className="content">
                {!spaceType && !appId && 
                    <Radio.Group onChange={handleTypeChange}>
                        <Radio.Button value={1}>
                            <div className='icon'><PersonalIcon/></div>
                            <h1>Personal</h1><p>Free</p>
                        </Radio.Button>
                        <Radio.Button value={2}>
                            <div className='icon'><BusinessIcon/></div>
                            <h1>Business</h1><p>invitation code</p>
                        </Radio.Button>
                    </Radio.Group>
                }
                {
                    (spaceType || appId) && 
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="applicationName"
                            label="Name"
                        >
                            <Input placeholder="My application" />
                        </Form.Item>
                        <Form.Item name="space" label={<>Storage space:&nbsp;<b>{space}G</b></>}>
                            <Slider min={0} max={spaceType === 1 ? 15 : 300} marks={marks} onChange={handleSliderChange}/>
                        </Form.Item>
                        <div className='price'>
                            <div>
                                <div>Price</div>
                                <div>0.2 FIL/GB</div>
                            </div>
                            <div>
                                <div>Max. cost</div>
                                <div><span>{numeral(costPerG * space).format('0,0.0000')} FIL</span>&nbsp;0 FIL</div>
                            </div>
                        </div>
                        {spaceType === 2 && !appId && 
                            <Form.Item
                                name="invitationCode"
                                label={<>Please enter the business invitation code,
                                    or contact <a href="mailto:contact@sao.network">contact@sao.network</a></>}
                            >
                                <Input/>
                            </Form.Item>
                        }
                        <Form.Item label="">
                            <Button type="primary" htmlType="submit" block disabled={space === 0}>
                                {appId ? "Update" : "Create"}
                            </Button>
                        </Form.Item>
                    </Form>
                }
           </div>
        </Modal>
    )
}