import Web3 from "web3";

export const clearLocalStorage = () => {
    window.localStorage.removeItem('address')
    window.localStorage.removeItem('signatureResult')
    window.localStorage.removeItem('signatureMessage')
    window.localStorage.removeItem('wallet_type')
}

let promise: any;

const personalSign = (provider: any, msg: string, fromAddress: string, pwd: string) => {
  if(promise){
    return promise;
  }
  msg = 'Welcome to StorageDAO for Web3!\n\n' +
  'Click "Sign" to continue. \nThis signature will cost 0 gas.\n\n' +
  'Wallet address:\n' +
  fromAddress + '\n\n' +
  'Nonce:\n' + msg;
  const web3 = new Web3(provider);
  promise = new Promise((resolve, reject) => {
    web3.eth.personal.sign(msg, fromAddress, pwd).then(res => {
      localStorage.setItem('address', fromAddress)
      localStorage.setItem('signatureResult', res)
      localStorage.setItem('signatureMessage', encodeURIComponent(msg))
      promise = undefined;
      resolve({address: fromAddress, signature: res, signatureMessage: encodeURIComponent(msg)})
    }).catch(err => {
      promise = undefined;
      reject(err)
    })
  })
  return promise
}

export const handleIsSignExpired = (account: string) => {
  let nowTime = Date.now();
  let address, signature, signatureMessage;

  address = localStorage.getItem('address')
  signature = localStorage.getItem('signatureResult')
  signatureMessage = localStorage.getItem('signatureMessage')

  if (!signature || !signatureMessage || address !== account) {
    return
  }

  const signTime: any = signatureMessage.split('Nonce%3A%0A')[1];

  if (nowTime - signTime > 604800000) {
    clearLocalStorage();
    window.location.reload()
  }

  return { address, signature, signatureMessage }
}

export const getSign = async (library: any, account: any, force:any) => {
  let signature, signatureMessage, address, storedSignature: any;
  if (!force) {
    storedSignature = handleIsSignExpired(account)
  }

  if (!storedSignature || (account !== storedSignature.address)) {
    signatureMessage = Date.now()

    if (library?.provider) {
      storedSignature = await personalSign(library.provider, signatureMessage.toString(), account, '')
      signature = storedSignature.signature
      signatureMessage = storedSignature.signatureMessage
      address = storedSignature.address
    }
  } else {
    signature = storedSignature.signature
    signatureMessage = storedSignature.signatureMessage
    address = storedSignature.address
  }

  return { signature, signatureMessage, address: account }
}
