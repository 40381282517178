import { Button, Progress, Table, Badge, Radio, Tooltip as AnTooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useState } from 'react';
import { useHistory } from "react-router";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { RouteComponentProps } from 'react-router-dom'
import Countdown from 'react-countdown';
import moment from 'moment';
import numeral from 'numeral';
import { useWeb3React } from '@web3-react/core'
import MiningSearch from 'components/MiningSearch';
import MinerModal from './MinerModal';
import { useWalletModalToggle } from 'state/application/hooks'
import { useBlockList, useDepositList, useMiningPoolDetail, useMiningPoolChangeData } from 'views/hooks';
import { useFilAddress } from 'state/account/hooks';
import formatBytes from 'utils/formatBytes';
import formatFIL from 'utils/formatFIL';
import Cid from 'components/Cid';
import { MiningPoolStatus, MiningPoolBadgeColor } from '../MiningPool'
import {ReactComponent as Back} from '../../assets/img/back.svg'
import {ReactComponent as MiningData} from '../../assets/img/miningdata.svg'
import {ReactComponent as Change} from '../../assets/img/change.svg'
import {ReactComponent as AccountInfo} from '../../assets/img/account-info.svg'
import {ReactComponent as NoData} from 'assets/img/nodata.svg'
import {ReactComponent as JoinIcon} from 'assets/img/Join.svg'
import PoolActive from 'assets/img/pool-active.png'
import PoolClosed from 'assets/img/pool-closed.png'
import FIL from 'assets/img/fil.png'

import './index.less'

const CountdownRender = ({days, hours, minutes, seconds, completed}: any) => {
    if (completed) {
        // Render a completed state
        return <span>--</span>;
      } else {
        // Render a countdown
        return <span>{days}d {hours < 10 ? `0${hours}`: hours}:{minutes < 10 ? `0${minutes}`: minutes}:{seconds < 10 ? `0${seconds}`: seconds}</span>;
      }
}

const MiningPoolDetail = (props: RouteComponentProps<{ productId: string }>) => {
    const {
      match: {
        params: { productId }
      }
    } = props;
    const history = useHistory();
    const [blockPagination, setBlockPagination] = useState<any>({current: 1, pageSize: 10})
    const [depositPagination, setDepositPagination] = useState<any>({current: 1, pageSize: 10})
    const blockList = useBlockList(productId, blockPagination);
    const depositList = useDepositList(productId, depositPagination);
    const poolDetail = useMiningPoolDetail(productId);
    const changeData = useMiningPoolChangeData(productId);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [activeList, setActiveList] = useState<string>('block');
    const toggleWalletModal = useWalletModalToggle()
    const { account, library } = useWeb3React()
    const filAddress = useFilAddress();

    const handleListChange = (e: any) => {
        setActiveList(e.target.value);
    }

    const handleBlockTableChange = (_pagination: any) => {
        setBlockPagination(_pagination)
    }

    const handleDepositTableChange = (_pagination: any) => {
        setDepositPagination(_pagination)
    }

    const handleJoin = () => {
        if(account && filAddress){
            setIsModalOpen(true)
        }else{
            toggleWalletModal();
        }
    }

    const blockColumns:ColumnsType<any> = [
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: (time: any) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            fixed: 'left',
            width: 110,
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            width: 90,
        },
        {
            title: 'Block ID',
            dataIndex: 'blockId',
            key: 'blockId',
            width: 120,
            render: (v: string) => <Cid type="block" value={v}/>,
        },
        {
            title: 'Rewards',
            dataIndex: 'reward',
            key: 'reward',
            width: 120,
            render: (v: any) => `${formatFIL(v)}`,
        }
    ];
    const depositColumns:ColumnsType<any> = [
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: (time: any) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
            fixed: 'left',
            width: 110,
        },
        {
            title: 'Message ID',
            dataIndex: 'messageId',
            key: 'messageId',
            fixed: 'left',
            width: 100,
            render: (v: string) => <Cid type="message" value={v}/>,
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
            width: 100,
            render: (v: string) => <Cid type="address" value={v}/>
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            width: 100,
            render: (v: string) => <Cid type="address" value={v}/>
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 140,
            render: (v: any) => `${formatFIL(v)}`
        },
    ];

    return (
        <div className="mining-pool-detail">
            <MiningSearch />
            <div className="container">
                <div className="back">
                    <div onClick={() => {history.push('/miningpool')}}><Back/>Back</div>
                    <div>Miner: {poolDetail.minerId}</div>
                </div>
                <div className="content" style={{padding: 0}}>
                    <div className={`descriptions ${poolDetail.status === 0 ? 'open' : 'mining'}`}>
                        <div>
                            <img src={poolDetail.status === 3 ? PoolClosed : PoolActive} alt="" />
                        </div>
                        <div>
                            <div>Product</div>
                            <div>{poolDetail.productName}</div>
                        </div>  
                        <div>
                            <div>{poolDetail.status > 1 ? 'Amount' : 'Target Amount'}</div>
                            <div>
                                <img className="fil" src={FIL} />{formatFIL(poolDetail.poolCapacity)}
                            </div>
                        </div>
                        {poolDetail.status > 1 ?
                            <>  
                                <div>
                                    <div>Mining Days</div>
                                    <div>
                                        {poolDetail.minedDays}
                                    </div>
                                </div>
                                <div>
                                    <div>Est. Maturity Date</div>
                                    <div>   
                                        {poolDetail.closeTime && 
                                            moment(poolDetail.closeTime).format('YYYY-MM-DD')
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div>
                                    <div>Progress</div>
                                    <div className="progress">
                                        <Progress 
                                            percent={poolDetail.progress * 100} 
                                            format={percent => `${numeral(percent).format('0.00')} %`}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>Closing Time</div>
                                    <div>
                                        {poolDetail.lockTime && 
                                            <Countdown
                                                date={new Date(poolDetail.lockTime).getTime()}
                                                renderer={CountdownRender}
                                            />
                                        }  
                                    </div>
                                </div>
                            </>
                        }  
                        {poolDetail.status > 2 && 
                            <>
                                <div>
                                    <div>Runtime</div>
                                    <div>{poolDetail.minedDays}</div>
                                </div>
                                <div>
                                    <div>Est. Maturity Date</div>
                                    <div>{moment(poolDetail.closeTime).format('YYYY-MM-DD')}</div>
                                </div>
                            </> 
                        }
                        <div className="status">
                            <div>Status</div>
                            <div>
                                <Badge color={MiningPoolBadgeColor[poolDetail.status]}/>
                                {MiningPoolStatus[poolDetail.status]}
                            </div>
                        </div>  
                        {poolDetail.status === 0 &&
                            <div className="btn-box">
                                <Button onClick={handleJoin}>Join</Button>
                            </div>
                        }
                    </div>
                    {poolDetail.status === 0 && <div className='join'><JoinIcon/>Join Now! Enjoy an early bird bonus <span>&nbsp;- Extra 20% APY!</span></div>}
                </div>
                <div className="account-info">
                    <div className="title">
                        <AccountInfo/> Product Overview
                    </div> 
                    <div className="content">
                        <div className="info">
                            <div>
                                <div>Total Balance</div>
                                <div>{formatFIL(poolDetail.totalBalance)}</div>
                            </div>
                            <div>
                                <div>Available Balance</div>
                                <div>{formatFIL(poolDetail.availableBalance)}</div>
                            </div>
                            <div>
                                <div> 
                                    <AnTooltip placement="right" title={'Including initial pledge and deal collateral'}>
                                    Mining Collateral&nbsp;<QuestionCircleFilled/>
                                    </AnTooltip>
                                </div>
                                <div>{formatFIL(Number(poolDetail.collateral) + Number(poolDetail.initialPledge))}</div>
                            </div>
                            <div>
                                <div>Locked Rewards</div>
                                <div>{formatFIL(poolDetail.lockedRewards)}</div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="data">
                    <div className="title">
                        <MiningData/> Product Mining Data       
                    </div>   
                    <div className="content">
                        <div className="first">
                            <div>
                                <div>Power Growth (24h)</div>
                                <div>{formatBytes(poolDetail.latestOneDayPowerGrowth)}</div>
                            </div>
                            <div>
                                <div>Adj. Power</div>
                                <div>{formatBytes(poolDetail.powerAdj)}</div>
                            </div>
                        </div>
                        <div className="second">
                            <div>
                                <div>Rewards (24h)</div>
                                <div>{formatFIL(poolDetail.latestOneDayRewards)}</div>
                            </div>    
                            <div>
                                <div>Total Rewards</div>
                                <div>{formatFIL(poolDetail.totalRewards)}</div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="change">
                    <div className="power">
                        <div className="title">
                            <Change/> Account Change
                        </div>  
                        <div className="content">
                            {changeData.length > 0 &&
                                <div className="chart">
                                    <ResponsiveContainer width="99%" height="100%" debounce={1}>
                                        <LineChart
                                            data={changeData}
                                            margin={{
                                                top: 15,
                                                right: 20,
                                                left: 5,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid vertical={false}/>
                                            <XAxis dataKey="time" tickFormatter={(time) => moment(time).format('MM-DD')}/>
                                            <YAxis width={80} tickFormatter={(v) => `${numeral(formatFIL(v)).format('0,0')} FIL`}/>
                                            <Tooltip labelFormatter={(time) => moment(time).format('YYYY-MM-DD')} formatter={(value: any, name: any) => [`${formatFIL(value)}`, name]}/>
                                            <Legend verticalAlign="bottom" wrapperStyle={{ fontSize: '14px', bottom : '10px' }}/>
                                            <Line type="monotone" name="Total Balance" dataKey="totalBalance" stroke="#19d4ae" dot={false}/>
                                            <Line type="monotone" name="Available Balance" dataKey="availableBalance" stroke="#5ab1ef" dot={false}/>
                                            <Line type="monotone" name="Initial Pledge" dataKey="initialPledge" stroke="#82ca3d" dot={false}/>
                                            <Line type="monotone" name="Locked Rewards" dataKey="lockedRewards" stroke="#ffb980" dot={false}/>
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            }
                            {changeData.length === 0 && 
                                <div className="nodata">
                                    <NoData/>
                                    <div>No data</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="earning">
                        <div className="title">
                            <Change/> Earning Change
                        </div>  
                        <div className="content">
                            {changeData.length > 0 &&
                                <div className="chart">
                                    <ResponsiveContainer width="99%" height="100%" debounce={1}>
                                        <LineChart
                                            data={changeData}
                                            margin={{
                                                top: 15,
                                                right: 20,
                                                left: 5,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid vertical={false}/>
                                            <XAxis dataKey="time" tickFormatter={(time) => moment(time).format('MM-DD')}/>
                                            <YAxis width={50} tickFormatter={(v) => `${numeral(formatFIL(v)).format('0,0')} FIL`}/>
                                            <Tooltip labelFormatter={(time) => moment(time).format('YYYY-MM-DD')} formatter={(value: any, name: any) => [`${formatFIL(value)}`, name]}/>
                                            <Legend verticalAlign="bottom" wrapperStyle={{ fontSize: '14px', bottom : '10px' }}/>
                                            <Line type="monotone" dataKey="earning" name="Earning" stroke="#8884d8" dot={false}/>
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            }
                            {changeData.length === 0 && 
                                <div className="nodata">
                                    <NoData/>
                                    <div>No data</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="tipsets">
                    <div className="title">
                        <Radio.Group defaultValue="block" onChange={handleListChange}>
                            <Radio.Button value="block">Block List</Radio.Button>
                            <Radio.Button value="deposit">Deposit List</Radio.Button>
                        </Radio.Group>
                    </div>  
                    {activeList === 'block' && <Table columns={blockColumns} rowKey="blockId" scroll={{y: 600}} pagination={{...blockPagination, total: blockList.total}} onChange={handleBlockTableChange} dataSource={blockList.list}/>}
                    {activeList === 'deposit' && <Table columns={depositColumns} rowKey="blockId" scroll={{y: 600}} pagination={{...depositPagination, total: depositList.total}} onChange={handleDepositTableChange} dataSource={depositList.list} />}
                </div> 
            </div>
            <MinerModal poolDetail={poolDetail} visible={isModalOpen} filAddress={filAddress} handleCancel={() => {setIsModalOpen(false)}} handleOk={() => {setIsModalOpen(true)}}/>
        </div>
    )
}

export default MiningPoolDetail
