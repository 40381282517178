import { handleIsSignExpired } from './txSign'

export const request = (url: string, config: any) => {
  return fetch(url, config).then((res: any) => {
    if (!res.ok) {
      throw Error('');
    }

    return res.json();
  }).then((resJson: any) => {
      return resJson;
  }).catch((error: any) => {
    console.error('');
  });
};

export const get = (url: string, headers?: any) => {
  return request(url, {
    method: 'GET',
    headers
  });
};

export const post = (url: string, data: any) => {
  return request(url, {
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json'
    },
    method: 'POST'
  });
};
