import { useState, useEffect } from 'react';
import { message, Modal, Button, Form, InputNumber, Checkbox } from 'antd';
import QRCode from 'qrcode.react';
import numeral from 'numeral';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import useActiveWeb3React from "hooks/useActiveWeb3React";
import formatFIL from 'utils/formatFIL';
import { cidStartAndEnd } from 'components/Cid'
import { useFetchDepositResult, useDepositResult } from 'state/account/hooks'
import {ReactComponent as Copy} from 'assets/img/copy.svg'
import './index.less'

let interval2: any;
export default function MinerModal({visible, handleOk, handleCancel, poolDetail, filAddress}: any) {
    const [agree, setAgree] = useState<boolean>(false)
    const [step, setStep] = useState<number>(1);
    const [amount, setAmount] = useState<any>();
    const [form] = Form.useForm();
    const { account, library } = useActiveWeb3React();
    const [startTime, setStartTime] = useState<string>('');
    const fetchDepositResult = useFetchDepositResult();
    const depositID = useDepositResult();
    const onFinish = async(values: any) => {
        setStep(2);
        setAmount(values.amount);
    }

    const handleAggree = (e: any) => {
        setAgree(e.target.checked);
    }

    useEffect(() => {
        if(account && library && step === 3){
            const date = new Date().getTime()
            localStorage.setItem('depositModal', `${date}`)
            setStartTime(`${date}`)
        }
    }, [account, library, step])

    useEffect(() => {
        if(startTime){
            interval2 = setInterval(() => {
                fetchDepositResult(startTime);
            }, 10000);
        }
    },[startTime])

    useEffect(() => {
        if(depositID && interval2){
            clearInterval(interval2);
            setStartTime('')
        }
    }, [depositID])

    return (
        <Modal className="minerModal" title={`Product: ${poolDetail.productName}`} footer={null} visible={visible} onOk={handleOk} onCancel={() => {setStep(1);setAgree(false);handleCancel();}}>
           <div className="content">
               {step === 1 &&
                <>
                    <div className="qraddress">
                        <div>
                            <div>Your Filecoin Address</div>
                            <div className='address'>{filAddress}</div>
                        </div>
                        <div>
                            <div>
                                Amount Left
                            </div>
                            <div className="amount">
                                {numeral(Number((Math.floor((poolDetail.poolCapacity - poolDetail.received)/ 1e18 * 10000)/10000).toFixed(4))).format('0,0.0000')} FIL
                            </div>
                        </div>
                    </div>
                    <Form form={form} layout="vertical" onFinish={onFinish} hideRequiredMark> 
                        <Form.Item 
                            name="amount" 
                            label="Deposit Amount" 
                            rules={[
                                { required: true, message: 'Please enter the Deposit Amount.'},
                                { pattern: /^\d+(\.\d{1,4})?$/, message: "Up to 4 decimal places can be entered" }
                            ]}
                        >
                            <InputNumber prefix="FIL" placeholder='0.0000' min={0.01} max={Number((Math.floor((poolDetail.poolCapacity - poolDetail.received) / 1e18 * 10000)/10000).toFixed(4))}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Next
                            </Button>
                        </Form.Item>
                    </Form>
                    <p>
                    If you need help, please contact: <a target="_blank" href="mailto:contact@sao.network">contact@sao.network</a>
                    </p>
                </>
               }
               {step === 3 &&
               <>
                    <div className="qraddress">
                        <div>
                            <div>
                                Transfer Amount
                            </div>
                            <div className="amount">
                                {numeral(amount).format('0,0.0000')} FIL
                            </div>
                        </div>
                        <div>
                            <div>
                                FIL Receive Address
                            </div>
                            <div className="qrcode">
                                <div><QRCode size={107} value={poolDetail.address}/></div>
                                <div>
                                    {poolDetail.address}
                                    <CopyToClipboard text={poolDetail.address}
                                        onCopy={() => {message.success('Copied!')}}>
                                        <div className="copyIcon"><Copy/>Copy</div>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul>
                        <li>Please deposit FIL to the address displayed above. via your binded Filecoin wallet address.</li>
                        <li>After blockchain confirmation (in 2 mins), you can view the mining details through your [Account] - [Mining Dashboard].</li>
                        <li><b>Get early bird bonus after transfer success.</b></li>
                    </ul>
                </>
                }
                {step === 2 && 
                    <div className="instructions">
                        <h3>
                            Mining instructions 
                        </h3>
                        <ol>
                            <li>Assets should be transferred from the designated address <b>{`${cidStartAndEnd(filAddress).start}...${cidStartAndEnd(filAddress).end}` || ''}</b></li>
                            <li>The address must be your wallet address rather than an exchange address</li> 
                        </ol>
                        <div className='help'>To avoid asset loss, ensure that the conditions above are met.
                            Please contact us via email <a target="_blank" href="mailto:contact@sao.network">contact@sao.network</a> if you need further help.
                        </div>
                        <div className='check'><Checkbox value={agree} onChange={handleAggree}>I have read and agree to the <a target="_blank" href=''>Disclaimer</a></Checkbox></div>
                        <Button onClick={() => {if(agree) {setStep(3)}}}>I understand and continue</Button>
                    </div>
                }
           </div>
        </Modal>
    )
}