import { createReducer } from '@reduxjs/toolkit'
import { getFilAddress, getDepositResult } from './actions'


export interface AccountState {
  readonly filAddress: string
  readonly status: number
  readonly depositID: string
}

const initialState: AccountState = {
  filAddress: '',
  status: 1,
  depositID: ''
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getFilAddress, (state, action) => {
      const { address, status } = action.payload
      state.filAddress = address
      state.status = status;
    })
    .addCase(getDepositResult, (state, action) => {
      state.depositID = action.payload;
    })
)
