import numeral from 'numeral';
function d(e: any) {
    var t;
    return null !== (t = e.toString()) && void 0 !== t ? numeral(t).format('0,0.0000') : numeral(e).format('0,0.0000')
}
export default function formatFIL(v: number | undefined) {
    // if (null == e) {
    //     var s = t.toString().padStart(19, "0");
    //     return "".concat(s.slice(0, -18), ".").concat(s.slice(-18)).replace(/\.?0*$/g, "")
    // }
    // return numeral((t / 1e18).toFixed(e)).format("0,0.0000")
    if(v === -Infinity || v === Infinity || v === undefined){
        return v
    }
    if(v == 0 || v == null){
        return '0 FIL'
    }
    const t = Math.abs(v)
    const s= t < 1e6 ? "".concat(d(t), " attoFIL") : t < 1e15 ? "".concat(d(t / 1e9), " nanoFIL") : t < 1e24 ? "".concat(d(t / 1e18), " FIL") : t < 1e33 ? "".concat(d(t / 1e27), " Billion FIL") : "".concat(d(t / 1e36), " Quintillion FIL")
    return v < 0 ? `-${s}` : s;
}