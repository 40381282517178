import React, { useEffect } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { Button } from 'antd'
import styled from 'styled-components'
import { useWalletModalToggle } from '../../state/application/hooks'
import { shortenAddress } from '../../utils'
import { ChainLogo } from '../CurrencyLogo'
import WalletModal from '../WalletModal'
import { NetworkContextName } from '../../constants'
import {ReactComponent as WalletIcon} from 'assets/img/wallet.svg'
import './index.less';

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  color: #000;
`


const AccountWraper = styled.span`
  margin-left: 10px;
`
// we want the latest one to come first, so return negative if a is after b
// function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
//   return b.addedTime - a.addedTime
// }

const SOCK = (
  <span role="img" aria-label="has socks emoji" style={{ marginTop: -4, marginBottom: -4 }}>
    🧦
  </span>
)

function Web3StatusInner({className} : any) {
  const { chainId, account, error } = useWeb3React()

  const toggleWalletModal = useWalletModalToggle()

  if (account) {
    return (
      <Button size="large" type="default" onClick={toggleWalletModal}>
        <WalletIcon fill={className === 'light' ? '#000' : '#fff'} height={20}/>
        <AccountWraper>{shortenAddress(account)}</AccountWraper>
      </Button>
    )
  } else if (error) {
    return (
      <Button size="large" onClick={toggleWalletModal} danger>
        <Text>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</Text>
      </Button>
    )
  } else {
    return (
      <Button size="large" type="primary" onClick={toggleWalletModal}>
        Connect Wallet
      </Button>
    )
  }
}

export default function Web3Status({className} : any) {
  const { active } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <div className={`web3-status ${className}`}>
      <Web3StatusInner className={className}/>
      <WalletModal />
    </div>
  )
}
